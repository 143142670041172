import {
  CountryCode,
  Locale,
} from '@by-goodiebox/subscription-platform-entities';

const API_URI = process.env.REACT_APP_GOODIEPAY_API_URI;

export interface AdyenPaymentResult {
  action?: unknown;
  details?: unknown;
  resultCode?: string;
  refusalReason?: string;
}

export async function createCheckout(
  country: CountryCode,
  locale: Locale,
  memberId: string,
  returnUrl: string,
) {
  try {
    const body = {
      country,
      locale,
      memberId,
      returnUrl,
    };

    const res = await fetch(`${API_URI}/adyen/create-checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (error) {
    throw error;
  }
}

export async function makePayment({
  memberId,
  paymentMethod,
  browserInfo,
  returnUrl,
  isBcmc,
  amount,
  invoiceId,
  token,
}: any): Promise<AdyenPaymentResult> {
  try {
    const body = {
      member_id: memberId,
      invoice_id: invoiceId,
      payment_method: paymentMethod,
      browser_info: browserInfo,
      return_url: returnUrl,
      is_bcmc: isBcmc,
      amount,
    };
    const res = await fetch(`${API_URI}/adyen/make-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    return await res.json();
  } catch (error) {
    throw error;
  }
}

const PAYMENT_DETAILS_TIMESTAMP = 'paymentDetailsTimestamp';

export const makeAdyenPaymentDetails = async (
  details: unknown,
  id: string,
  token: string,
): Promise<AdyenPaymentResult | null> => {
  const body: { member_id: string; details: unknown } = {
    member_id: id,
    details,
  };

  const now = +new Date();
  const lastPaymentDetailsMade = localStorage.getItem(
    PAYMENT_DETAILS_TIMESTAMP,
  );
  if (lastPaymentDetailsMade && now - Number(lastPaymentDetailsMade) < 5000) {
    // eslint-disable-next-line no-console
    console.warn(`Disallow making more than one payment consecutively.`);
    // Prevent the same request made accidentally
    return null;
  }

  localStorage.setItem(PAYMENT_DETAILS_TIMESTAMP, (+new Date()).toString());
  // eslint-disable-next-line no-console
  console.info(`Saving timestamp for first payment registered.`);
  const res = await fetch(`${API_URI}/adyen/make-payment-details`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return res.json();
};
