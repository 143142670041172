import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import {
  AdyenPaymentResult,
  createCheckout,
  makeAdyenPaymentDetails,
  makePayment,
} from './services/payment';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  CountryCode,
  Locale,
} from '@by-goodiebox/subscription-platform-entities';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import {
  getBrowserName,
  isErrorPaymentResult,
  isRefusedPaymentResult,
  isSuccessPaymentResult,
  mapAdyenResult,
} from './utils/helpers';
import { logger } from '@by-goodiebox/logger';
import { CheckoutData } from 'types/adyen';
import { CbObjOnBinLookup } from '@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types';

const CLIENT_KEY = process.env.REACT_APP_ADYEN_CLIENT_KEY;

if (!CLIENT_KEY) {
  throw Error('Expected CLIENT_KEY from adyen');
}

function isBcmcBin({ type, supportedBrands }: CbObjOnBinLookup) {
  return (
    type === 'bcmc' ||
    (type === 'card' &&
      supportedBrands?.length === 1 &&
      supportedBrands?.[0] === 'bcmc')
  );
}

interface Params {
  country: CountryCode;
  locale: Locale;
  memberId: string;
  token: string;
  returnUrl: string;
}

const initialParams = {
  country: '' as CountryCode,
  locale: '' as Locale,
  memberId: '',
  token: '',
  returnUrl: '',
};

const MARGIN = 8;

export default function Checkout() {
  const [checkoutData, setCheckoutData] = useState<CheckoutData>();
  const [params, setParams] = useState<Params>(initialParams);
  const paymentRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();
  const isSubmitting = useRef<boolean>(false);
  const prevHeight = useRef<number>(0);
  const browser = getBrowserName();
  const dropIn = useRef<any>();

  useEffect(() => {
    loadParams();
    const unsubscribe = subscribeToHeight();
    return unsubscribe;
  }, []);

  useEffect(() => {
    initiateCheckout();
  }, [params]);

  useEffect(() => {
    if (!paymentRef.current || !checkoutData) {
      return;
    }
    mountCheckout();
  }, [paymentRef, checkoutData]);

  function loadParams() {
    const queryString = window.location.search.replace('?', '');
    const _params = queryString
      .split('&')
      .map((param) => param.split('='))
      .reduce((values: Params, [key, value, source]) => {
        // Needed because we split on = and return url looks like this: returnUrl={URL}?source={SOURCE}
        const paramValue = source ? `${value}=${source}` : value;
        return { ...values, [key]: paramValue };
      }, initialParams);
    setParams(_params);
  }

  function subscribeToHeight() {
    var root = document.getElementById('root');
    if (root) {
      const resizeObserver = new ResizeObserver(() => {
        if (root) {
          var height = root.scrollHeight;
          if (height === prevHeight.current) {
            return;
          }
          prevHeight.current = height;
          window.top?.postMessage(height + MARGIN, '*');
        }
      });
      resizeObserver.observe(root);
      return () => (root ? resizeObserver.unobserve(root) : void 0);
    }
    return () => void 0;
  }

  async function initiateCheckout() {
    try {
      clearTimeout(timeout.current);
      const { country, locale, memberId, token, returnUrl } = params;

      if (!country || !locale || !memberId || !token) {
        timeout.current = setTimeout(() => {
          logger.error('Failed to initiate checkout');
        }, 5000);
        return;
      }
      const _checkout = await createCheckout(
        country,
        locale,
        memberId,
        returnUrl,
      );

      setCheckoutData(_checkout);
    } catch (error: unknown) {
      onError();
      logger.error('Failed to initate checkout', error);
    }
  }

  async function mountCheckout() {
    try {
      if (!checkoutData) {
        return;
      }
      let isBcmc = false;
      const { session, locale, environment } = checkoutData;

      const config: CoreOptions = {
        environment,
        clientKey: CLIENT_KEY,
        session,
        locale,
        onSubmit: (payload: any) => {
          onSubmit({ ...payload, data: { ...payload.data, isBcmc } });
        },
        onAdditionalDetails,
        onError,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            onBinLookup: (event: CbObjOnBinLookup) => {
              isBcmc = isBcmcBin(event);
            },
          },
        },
      };
      const checkout = await AdyenCheckout(config);

      if (paymentRef.current) {
        dropIn.current = checkout
          .create('dropin', {
            showStoredPaymentMethods: false,
            onReady,
          })
          .mount(paymentRef.current);
      }
    } catch (error: unknown) {
      onError();
      logger.error('Failed to mount checkout', error);
    }
  }

  async function onSubmit(payload: any) {
    try {
      const {
        isValid,
        data: { paymentMethod, browserInfo, isBcmc },
      } = payload;
      if (!isValid || isSubmitting.current) {
        return;
      }

      if (window.top) {
        window.top.postMessage('prepayment', '*');
      }

      const { returnUrl } = params;
      isSubmitting.current = true;

      const { memberId, token } = params;
      const result = await makePayment({
        memberId,
        paymentMethod,
        browserInfo,
        isBcmc,
        returnUrl,
        token,
      });

      handlePaymentResult(result);
    } catch (error: unknown) {
      onError();
      logger.error('Failed to make payment', error);
    }
  }

  async function onAdditionalDetails(payload: any) {
    try {
      const result = await makeAdyenPaymentDetails(
        payload.data,
        params.memberId,
        params.token,
      );

      if (result) {
        handlePaymentResult(result);
      }
    } catch (error: unknown) {
      onError();
      logger.error('Failed to make payment details', error);
    }
  }

  const handlePaymentResult = (result: AdyenPaymentResult): void => {
    if (result.action) {
      onAction(result.action);
      return;
    }

    const paymentResult = mapAdyenResult(result);
    if (isSuccessPaymentResult(paymentResult)) {
      onSuccess();
      dropIn.current.unmount();
    } else if (isRefusedPaymentResult(paymentResult)) {
      onRefusal();
    } else if (isErrorPaymentResult(paymentResult)) {
      onError();
    }
  };

  function onReady() {
    if (window.top) {
      window.top.postMessage('ready', '*');
    }
  }

  function onSuccess() {
    if (window.top) {
      window.top.postMessage('success', '*');
    }
    if (browser === 'Apple Safari') {
      const redirectResult =
        navigator.userAgent.indexOf('Safari') !== -1 ? 'AP=true' : '';
      redirect(redirectResult);
      return;
    }
  }

  function onRefusal() {
    remount();
    if (window.top) {
      window.top.postMessage('refused', '*');
    }
    if (browser === 'Apple Safari') {
      redirect('refused');
    }
  }

  function onError() {
    remount();
    if (window.top) {
      window.top.postMessage('error', '*');
    }
    if (browser === 'Apple Safari') {
      redirect('error');
    }
  }

  function onAction(action: any) {
    if (action instanceof Object && action.hasOwnProperty('paymentData')) {
      localStorage.setItem(
        'paymentData',
        (action as { paymentData: string }).paymentData,
      );
    }
    dropIn.current.handleAction(action);
    return;
  }

  function redirect(redirectResult: string) {
    window.location.href = `${params.returnUrl}&${redirectResult}`;
  }

  function remount() {
    dropIn.current.unmount();
    dropIn.current.remount();
  }

  return <div ref={paymentRef} className="payment"></div>;
}
