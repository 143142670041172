import { AdyenPaymentResult } from 'services/payment';

export const mapAdyenResult = ({
  resultCode,
  refusalReason,
}: AdyenPaymentResult): PaymentResult => {
  switch (resultCode) {
    case 'Authorised':
      return { status: 'success' };
    case 'Error':
      return { status: 'error', message: refusalReason };
    case 'Pending':
      return { status: 'success', message: 'Payment pending' };
    case 'Received':
      return { status: 'success', message: 'Payment received' };
    case 'Refused':
      return { status: 'refused', message: refusalReason };
    default:
      throw new Error(`Unable to handle resultCode '${resultCode}'`);
  }
};

type PaymentResultStatus = 'success' | 'refused' | 'error';

export interface PaymentResult<
  T extends PaymentResultStatus = PaymentResultStatus,
> {
  status: T;
  message?: string;
}

// src/components/Payment/Adyen.tsx

export type SuccessPaymentResult = PaymentResult<'success'>;
export type RefusedPaymentResult = PaymentResult<'refused'>;
export type ErrorPaymentResult = PaymentResult<'error'>;

export const isSuccessPaymentResult = (
  paymentResult: PaymentResult,
): paymentResult is SuccessPaymentResult => paymentResult.status === 'success';

export const isRefusedPaymentResult = (
  paymentResult: PaymentResult,
): paymentResult is RefusedPaymentResult => paymentResult.status === 'refused';

export const isErrorPaymentResult = (
  paymentResult: PaymentResult,
): paymentResult is ErrorPaymentResult => paymentResult.status === 'error';

// https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
export const getBrowserName = () => {
  const ua = navigator.userAgent;
  if (ua.includes('Firefox')) {
    return 'Mozilla Firefox';
  } else if (ua.includes('SamsungBrowser')) {
    return 'Samsung Internet';
  } else if (ua.includes('Opera') || ua.includes('OPR')) {
    return 'Opera';
  } else if (ua.includes('Trident')) {
    return 'Microsoft Internet Explorer';
  } else if (ua.includes('Edge')) {
    return 'Microsoft Edge (Legacy)';
  } else if (ua.includes('Edg')) {
    return 'Microsoft Edge (Chromium)';
  } else if (ua.includes('Chrome')) {
    return 'Google Chrome or Chromium';
  } else if (ua.includes('Safari')) {
    return 'Apple Safari';
  } else {
    return 'unknown';
  }
};
